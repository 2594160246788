import * as Yup from "yup";
export const schema = Yup.object()
  .shape({
    caseNumber: Yup.string()
      .matches(/^[0-9]{2}\.[0-9]+$/, translatedLabels["Invalid case number"])
      .required(translatedLabels["Required"]),
    firstName: Yup.string().required(translatedLabels["Required"]),
    lastName: Yup.string().required("Required"),
    socialSecurityNumber: Yup.string()
      .matches(/^[0-9]+$/, translatedLabels["Must be only digits"])
      .min(6, translatedLabels["Must be exactly 6 digits"])
      .max(6, translatedLabels["Must be exactly 6 digits"])
      .required(translatedLabels["Required"]),
    email: Yup.string()
      .email(translatedLabels["Invalid email address"])
      .required(translatedLabels["Required"]),
    bankAccountNumber: Yup.string().required(translatedLabels["Required"]),
    message: Yup.string().nullable(true),
    widget_id: Yup.string(),
  })
  .noUnknown(false);

export const totalCalc = (form, expenses, remove, update) => {
  const summary = form.querySelector(".summary-wrapper");
  summary.innerHTML = "";

  const rowTemplate = form.querySelector("#expense-row");

  // add driving expenses
  expenses.driving.forEach((expense, i) => {
    const row = expenseRow(
      rowTemplate,
      expense,
      i,
      translatedLabels["Driving Allowance"],
      remove,
      update,
      "#add-driving-allowance"
    );
    summary.append(row);
  });

  // add diet expenses
  expenses.diet.forEach((expense, i) => {
    const row = expenseRow(
      rowTemplate,
      expense,
      i,
      translatedLabels["Diet"],
      remove,
      update,
      "#add-diet"
    );
    summary.append(row);
  });

  // add receipt expenses
  expenses.receipt.forEach((expense, i) => {
    const row = expenseRow(
      rowTemplate,
      expense,
      i,
      translatedLabels["Receipt"],
      remove,
      update,
      "#add-receipt"
    );
    summary.append(row);
  });

  // calculate total and add it to the summary
  let total = expenses.driving.reduce((acc, cur) => {
    return acc + cur.total;
  }, 0);
  total += expenses.diet.reduce((acc, cur) => {
    return acc + cur.total;
  }, 0);
  total += expenses.receipt.reduce((acc, cur) => {
    return acc + cur.amount;
  }, 0);

  if (
    expenses.driving.length === 0 &&
    expenses.diet.length === 0 &&
    expenses.receipt.length === 0
  ) {
    summary.innerHTML = `<strong>${translatedLabels["No expenses added"]}</strong>`;
  } else {
    const totalRow = rowTemplate.content.cloneNode(true);
    totalRow.querySelector(".expense-row").classList.add("font-bold", "mt-4");
    totalRow.querySelector(".row-name").innerHTML = translatedLabels.total;
    totalRow.querySelector(".row-value").innerHTML = `${total.toFixed(0)} kr`;
    totalRow.querySelector(".row-actions").innerHTML = "";
    summary.append(totalRow);
  }
};

// create a row for an expense
const expenseRow = (rowTemplate, expense, i, title, remove, update, type) => {
  const row = rowTemplate.content.cloneNode(true);
  if (i === 0) {
    row.querySelector(".row-name").innerHTML = title;
  }
  row.querySelector(".row-value").innerHTML = `${
    typeof expense.total === "number"
      ? `${Math.round(expense.total)} kr`
      : `${Math.round(expense.amount)} kr`
  } - ${expense.date}`;

  row.querySelector(".remove-expense").addEventListener("click", (e) => {
    e.preventDefault();
    remove(i);
  });
  row.querySelector(".update-expense").addEventListener("click", (e) => {
    e.preventDefault();
    update(i, expense, type);
  });

  return row;
};
