import * as yup from 'yup'

const dietForm = (form, callback, initialValues = {}, index) => {
  const expense = {
    date: '',
    intervals: 1,
    people: 1,
    total: 0,
    ...initialValues
  }

  // set initial values if any
  if(Object.keys(initialValues).length > 0){
    const dateInput = form.querySelector('#form-input-date')

    dateInput.closest('.form-group').classList.add('filled')

    dateInput.value = expense.date

    form.querySelectorAll('.intervals input.form-radio-input').forEach(rad => {
      if(rad.value == expense.intervals) rad.checked = true
    })

    form.querySelectorAll('.people input.form-radio-input').forEach(rad => {
      if(rad.value == expense.people) rad.checked = true
    })

    updateTotal(form, expense)
  }

  form.querySelector('#form-input-date').addEventListener('change', (e) => {
    expense.date = e.target.value
    form.querySelector('#form-input-date').parentElement.classList.remove('error')
  })
  form.querySelector('#form-input-date').addEventListener('keyup', (e) => {
    expense.date = e.target.value
    form.querySelector('#form-input-date').parentElement.classList.remove('error')
  })

  form.querySelectorAll('.intervals input.form-radio-input').forEach(rad => {
    rad.addEventListener('change', (e) => {
      expense.intervals = parseInt(e.target.value)
      expense.total = updateTotal(form, expense)
    })
  })
  form.querySelectorAll('.people input.form-radio-input').forEach(rad => {
    rad.addEventListener('change', (e) => {
      expense.people = parseInt(e.target.value)
      expense.total = updateTotal(form, expense)
    })
  })

  form.addEventListener('submit', async (e) => {
    e.preventDefault()
    await submit(form, callback, expense, index)
  })
}

const updateTotal = (form, expense) => {
  const price = parseFloat(form.dataset.price) || 0
  const minInterval = parseInt(form.dataset.interval) || 0

  let total = 0
  if(minInterval <= expense.intervals){
    total = Math.round((expense.intervals*expense.people*price))
    const totalText = `${expense.intervals}${expense.people > 1 ? ` * ${expense.people}` : ''} * ${price} kr = ${total} kr`
    form.querySelector('.total-value').innerHTML = totalText
  }else{
    form.querySelector('.total-value').innerHTML = '0 kr'
  }

  return total
}

const submit = async (form, callback, expense, index) => {
  const schema = yup.object().shape({
    date: yup.string().required('Dato er påkrævet'),
    intervals: yup.number().required('Antal intervaler er påkrævet'),
    people: yup.number().required('Antal personer er påkrævet')
  })

  try {
    const data = await schema.validateSync(expense, { abortEarly: false })
    callback(data, index)
  } catch (err) {
    Array.isArray(err.inner) && err.inner.forEach(e => {
      if(e.path === 'from' || e.path === 'to'){
        const input = form.querySelector(`#form-input-${e.path}`)
        input.parentElement.classList.add('error')
        input.addEventListener('focus', function (evt) { input.parentElement.classList.remove('error') }, { once: true })
    }
      if(e.path === 'date'){
        const input = form.querySelector(`#form-input-date`)
        input.parentElement.classList.add('error')
        input.addEventListener('focus', function (evt) { input.parentElement.classList.remove('error') }, { once: true })
      }
    })
  }
}

export default dietForm