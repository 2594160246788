import axios from 'axios'

const autocompleteField = async (container, initialValues = null, fromCallback = ()=>{}, toCallback = ()=>{}, distanceCallback = ()=>{}) => {  
  let fromPlaceId = initialValues ? initialValues.from.formatted_address : null
  let toPlaceId = initialValues ? initialValues.to.formatted_address : null
  const fromAutocomplete = new google.maps.places.Autocomplete(container.querySelector('.form-input-from'), {
    // types: ['establishment', 'address'],
    componentRestrictions: { country: 'no' },
    fields: ['name', 'formatted_address']
  })
  const toAutocomplete = new google.maps.places.Autocomplete(container.querySelector('.form-input-to'), {
    // types: ['establishment', 'address'],
    componentRestrictions: { country: 'no' },
    fields: ['name', 'formatted_address']
  })

  fromAutocomplete.addListener('place_changed', async () => {
    const place = fromAutocomplete.getPlace()
    fromCallback(place)
    fromPlaceId = place.formatted_address

    if(toPlaceId){
      const distance = await getDistance(fromPlaceId, toPlaceId)
      distanceCallback(distance)
    }
  })

  toAutocomplete.addListener('place_changed', async () => {
    const place = toAutocomplete.getPlace()
    toCallback(place)
    toPlaceId = place.formatted_address

    if(fromPlaceId){
      const distance = await getDistance(fromPlaceId, toPlaceId)
      distanceCallback(distance)
    }
  })
}

const getDistance = async (from, to) => {
  const matrix = new google.maps.DistanceMatrixService()
  const obj = await matrix.getDistanceMatrix({
    origins: [from],
    destinations: [to],
    travelMode: google.maps.TravelMode.DRIVING,
  })
  
  return obj.rows[0].elements[0].distance.value
}

export default autocompleteField