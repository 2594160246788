import distance from './distance'
import * as yup from 'yup'
import { updateInputFields } from '../refund'
import { create } from 'yup/lib/Reference'

// find key in array of objects by property value
const findKey = (array, property, value) => {
  return array.findIndex(obj => obj[property] === value)
}

const drivingFrom = (form, callBack, initialValues = {}, index) => {
  let i = 0

  const drivingExpense = {
    date: '',
    tolls: 0,
    total: 0,
    driving: [],
    ...initialValues
  }

  const createRow = (index) => {
    const button = form.querySelector('.add-row')
    const newRow = document.querySelector('#travel-row').content.cloneNode(true)
    newRow.querySelector('.remove-row').addEventListener('click', (e) => {
      e.preventDefault()
      e.currentTarget.parentElement.remove()
      drivingExpense.driving = drivingExpense.driving.filter((item) => item.id !== index)
      drivingExpense.total = updateTotal(form, drivingExpense)
    })
    newRow.querySelector('.form-input-from').setAttribute('name', `from[${index}]`)
    newRow.querySelector('.form-input-from').setAttribute('id', `form-input-from-${index}`)
    newRow.querySelector('[for="form-input-from"]').setAttribute('for', `form-input-from-${index}`)
    newRow.querySelector('.form-input-to').setAttribute('name', `to[${index}]`)
    newRow.querySelector('.form-input-to').setAttribute('id', `form-input-to-${index}`)
    newRow.querySelector('[for="form-input-to"]').setAttribute('for', `form-input-to-${index}`)
    newRow.querySelector('.form-direction #form-input-direction-0').setAttribute('name', `direction[${index}]`)
    newRow.querySelector('.form-direction #form-input-direction-0').setAttribute('id', `form-input-direction-0-${index}`)
    newRow.querySelector('.form-direction label[for="form-input-direction-0"]').setAttribute('for', `form-input-direction-0-${index}`)
    newRow.querySelector('.form-direction #form-input-direction-1').setAttribute('name', `direction[${index}]`)
    newRow.querySelector('.form-direction #form-input-direction-1').setAttribute('id', `form-input-direction-1-${index}`)
    newRow.querySelector('.form-direction label[for="form-input-direction-1"]').setAttribute('for', `form-input-direction-1-${index}`)
    updateInputFields(newRow.querySelectorAll('.form-input-from, .form-input-to'))
    button.parentNode.insertBefore(newRow, button)
    const row = button.previousElementSibling
    if(!drivingExpense.driving[findKey(drivingExpense.driving, 'id', index)]){
      drivingExpense.driving.push({
        id: index,
        from: null,
        to: null,
        distance: 0,
        ways: 1
      })
    }

    // add google places autocomplete and total calculation
    distance(row, initialValues.length > 0 ? initialValues : null, (place) => {
      drivingExpense.driving[findKey(drivingExpense.driving, 'id', index)].from = place
      row.querySelector('.form-input-from').parentElement.classList.remove('error')
    }, (place) => {
      drivingExpense.driving[findKey(drivingExpense.driving, 'id', index)].to = place
      row.querySelector('.form-input-to').parentElement.classList.remove('error')
    }, (dist) => {
      const price = parseFloat(form.dataset.price) || 0
      drivingExpense.driving[findKey(drivingExpense.driving, 'id', index)].distance = dist
      drivingExpense.driving[findKey(drivingExpense.driving, 'id', index)].total = Math.round(dist/1000*price) * drivingExpense.driving[findKey(drivingExpense.driving, 'id', index)].ways
      drivingExpense.total = updateTotal(form, drivingExpense)
    })

    row.querySelectorAll('input.form-radio-input').forEach(rad => {
      rad.addEventListener('change', (e) => {
        const price = parseFloat(form.dataset.price) || 0
        drivingExpense.driving[findKey(drivingExpense.driving, 'id', index)].ways = parseInt(e.target.value)
        drivingExpense.driving[findKey(drivingExpense.driving, 'id', index)].total = Math.round(drivingExpense.driving[findKey(drivingExpense.driving, 'id', index)].distance / 1000 * price) * parseInt(e.target.value)
        drivingExpense.total = updateTotal(form, drivingExpense)
      })
    })

    ++i
  }

  // set initial values if any
  if(Object.keys(initialValues).length > 0){
    drivingExpense.driving.forEach(item => {
      createRow(item.id)

      const fromInput = form.querySelector(`#form-input-from-${item.id}`)
      const toInput = form.querySelector(`#form-input-to-${item.id}`)
      
      fromInput.closest('.form-group').classList.add('filled')
      toInput.closest('.form-group').classList.add('filled')

      fromInput.value = item.from.name
      toInput.value = item.to.name

      form.querySelectorAll(`#form-input-direction-0-${item.id}, #form-input-direction-1-${item.id}`).forEach(rad => {
        if(rad.value == item.ways) rad.checked = true
      })
    })
    
    const dateInput = form.querySelector('#form-input-date')
    const tollsInput = form.querySelector('#form-input-toll')

    
    dateInput.closest('.form-group').classList.add('filled')
    tollsInput.closest('.form-group').classList.add('filled')

    dateInput.value = drivingExpense.date
    tollsInput.value = drivingExpense.tolls
    
    

    updateTotal(form, drivingExpense)
  }

  form.querySelector('.add-row').addEventListener('click', (e) => {
    e.preventDefault()
    const index = i

    createRow(index)
  })

  form.querySelector('#form-input-date').addEventListener('change', (e) => {
    drivingExpense.date = e.target.value
  })
  form.querySelector('#form-input-date').addEventListener('keyup', (e) => {
    drivingExpense.date = e.target.value
  })

  form.querySelector('#form-input-toll').addEventListener('change', (e) => {
    drivingExpense.tolls = isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value)
    drivingExpense.total = updateTotal(form, drivingExpense)
  })
  form.querySelector('#form-input-toll').addEventListener('keyup', (e) => {
    drivingExpense.tolls = isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value)
    drivingExpense.total = updateTotal(form, drivingExpense)
  })

  form.addEventListener('submit', async (e) => {
    e.preventDefault()
    await submit(form, callBack, drivingExpense, index)
  })
}

const updateTotal = (form, expense) => {
  const price = parseFloat(form.dataset.price) || 0
  const minDist = parseInt(form.dataset.distance) || 0
  
  let total = 0
  let totalDistance = 0
  expense.driving.forEach(item => {
    totalDistance += item.distance/1000*item.ways
  })

  total = Math.round(totalDistance*price+expense.tolls)

  if(totalDistance > minDist){
    const totalText = `${Math.round(totalDistance)} km * ${price} kr${expense.tolls > 0 ? ` + ${expense.tolls} kr` : ''} = ${total} kr`
    form.querySelector('.total-value').innerHTML = totalText
  }else{
    form.querySelector('.total-value').innerHTML = translatedLabels.smallDistance.replace('{distance}', Math.round(totalDistance))
  }
  return total
}

const submit = async (form, callback, expense, index) => {
  const schema = yup.object().shape({
    driving: yup.array().of(yup.object().shape({
      from: yup.object().shape({
        name: yup.string().required('Du måste ange en plats'),
        formatted_address: yup.string().required('Du måste ange en plats')
      }),
      to: yup.object().shape({
        name: yup.string().required('Du måste ange en plats'),
        formatted_address: yup.string().required('Du måste ange en plats')
      }),
      ways: yup.number().required('Du måste ange antal körningar'),
      distance: yup.number().required('Du måste ange en avståndsangivelse')
    })),
    date: yup.string().required('Du måste ange en datum'),
    tolls: yup.number(),
    total: yup.number().required('Du måste ange en total')
  })

  try {
    const data = await schema.validateSync(expense, { abortEarly: false })
    callback(data, index)
  } catch (err) {
    console.log(err);
    err && err.inner.forEach(e => {
      if(e.path === 'from' || e.path === 'to')
        form.querySelector(`#form-input-${e.path}`).parentElement.classList.add('error')

      if(e.path === 'date')
        form.querySelector(`#form-input-date`).parentElement.classList.add('error')
    })
  }
}

export default drivingFrom