import { findParentElement, formatDate } from "../utils/helpers";
import drivingFrom from "./refund/drivingForm";
import dietFrom from "./refund/dietForm";
import receiptFrom from "./refund/receiptForm";
import { totalCalc, schema } from "./refund/mainForm";
import axios from "axios";

const refundForms = document.querySelectorAll(".static-widget.refund");
let expenses = {
  driving: [],
  diet: [],
  receipt: [],
};
refundForms.forEach((form) => {
  const addButtons = form.querySelectorAll(".add-button");
  const closeButton = form.querySelector("#closeConsentOverlay");
  addButtons.forEach((addButton) =>
    addButton.addEventListener("click", (e) => openModal(e, form))
  );
  closeButton.addEventListener("click", () => closeModal(form));

  form.querySelector(".refund-form").addEventListener("submit", async (e) => {
    e.preventDefault();

    const formData = new FormData(form.querySelector(".refund-form"));
    const formDataObj = [...formData].reduce((o, [k, v]) => {
      o[k] = v;
      return o;
    }, {});
    try {
      await schema.validate(formDataObj);
    } catch (err) {
      const input = form.querySelector(`#form-input-${err.path}`);
      input.focus();
      input.parentElement.classList.add("error");
      input.addEventListener(
        "blur",
        function (evt) {
          input.parentElement.classList.remove("error");
        },
        { once: true }
      );

      return false;
    }

    formData.append("expenses", JSON.stringify(expenses));
    expenses.receipt
      .map((receipt) => receipt.file)
      .forEach((file) => formData.append("receipts[]", file));
    form.querySelector('button[type="submit"]').disabled = true;
    form.querySelector('button[type="submit"]').classList.add("loading");

    const { data } = await axios
      .post("/actions/module/refund", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .catch(() => {
        form.querySelector('button[type="submit"]').disabled = false;
        form.querySelector('button[type="submit"]').classList.remove("loading");
        form.querySelector(".refund-form").classList.remove("hidden");
        form.querySelector(".refund-form-success").classList.add("hidden");
        form.querySelector(".refund-form-error").classList.remove("hidden");
        form.querySelector(".refund-form-error").innerHTML =
          'Noe gikk galt. Vennligst ta kontakt på <a href="mailto:refusjon@vertikalhelse.no">refusjon@vertikalhelse.no</a>';
      });
    form.querySelector('button[type="submit"]').disabled = false;
    form.querySelector('button[type="submit"]').classList.remove("loading");

    if (data.success) {
      form.querySelector(".refund-form").classList.add("hidden");
      const text = form.querySelector(".text-module");
      if (text) {
        text.classList.add("hidden");
      }
      form.querySelector(".refund-form-success").classList.remove("hidden");
    } else {
      form.querySelector(".refund-form").classList.remove("hidden");
      form.querySelector(".refund-form-success").classList.add("hidden");
      form.querySelector(".refund-form-error").classList.remove("hidden");
      form.querySelector(".refund-form-error").innerHTML = data.message;
    }
  });
});

const openModal = (e, form, initialValues, i = null) => {
  const type = e.currentTarget.href.split("#")[1];
  const template = document.querySelector(`#${type}`);
  const modalContent = template.content.cloneNode(true);

  const modal = form.querySelector("#consentOverlay");
  modal.querySelector(".modal-content-inner").append(modalContent);
  const inputFields = modal.querySelectorAll(".form-control");
  updateInputFields(inputFields);
  modal.classList.remove("hidden");
  const formSelector = modal.querySelector("form");

  // recursive function to update on state change the expenses summary
  const totalCalcHandler = (callback, type) =>
    totalCalc(
      form,
      expenses,
      (removeIndex) => {
        expenses[type] = expenses[type].filter((val, i) => i !== removeIndex);
        callback(callback);
      },
      (updateIndex, expense, type) => {
        openModal(
          { currentTarget: form.querySelector(`a[href="${type}"]`) },
          form,
          expense,
          updateIndex
        );
        callback(callback);
      }
    );

  if (type === "add-driving-allowance") {
    drivingFrom(
      formSelector,
      (expense, index) => {
        if (typeof index === "number") {
          expenses.driving[index] = expense;
        } else {
          expenses.driving.push(expense);
        }

        totalCalcHandler(totalCalcHandler, "driving");

        closeModal(form);
      },
      initialValues,
      i
    );
  } else if (type === "add-diet") {
    dietFrom(
      formSelector,
      (expense, index) => {
        if (typeof index === "number") {
          expenses.diet[index] = expense;
        } else {
          expenses.diet.push(expense);
        }
        totalCalcHandler(totalCalcHandler, "diet");
        closeModal(form);
      },
      initialValues,
      i
    );
  } else if (type === "add-receipt") {
    receiptFrom(
      formSelector,
      (expense, index) => {
        if (typeof index === "number") {
          expenses.receipt[index] = expense;
        } else {
          expenses.receipt.push(expense);
        }

        totalCalcHandler(totalCalcHandler, "receipt");
        closeModal(form);
      },
      initialValues,
      i
    );
  }
};

const closeModal = (form) => {
  const modal = form.querySelector("#consentOverlay");
  modal.classList.add("hidden");
  modal.querySelector(".modal-content-inner").innerHTML = "";
};

// form animations
export const updateInputFields = (inputFields) => {
  inputFields.forEach((inputField) => {
    inputField.addEventListener("focus", function (evt) {
      const target = evt.currentTarget ? evt.currentTarget : evt.srcElement;
      let parentElement = findParentElement(target, ".form-group");
      parentElement.classList.add("focused");
    });

    inputField.addEventListener("blur", function (evt) {
      const target = evt.currentTarget ? evt.currentTarget : evt.srcElement;
      let parentElement = findParentElement(target, ".form-group");
      let inputWrapper = findParentElement(target, ".form-input-wrapper");
      parentElement.classList.remove("focused");

      if (target.id !== "agreementNumber" || target.id !== "companyName") {
        if (target.value === "") {
          parentElement.classList.remove("filled");
        } else {
          parentElement.classList.add("filled");
        }
      }

      // if the input is required validate it with schema and add valid class to parent or error class
      const inSchema = schema._nodes.find((node) => node === target.name);
      if (target.required && inSchema) {
        schema
          .validateAt(target.name, { [target.name]: target.value })
          .then((res) => {
            parentElement.classList.remove("error");
            parentElement.classList.add("valid");
            inputWrapper.classList.remove("error");
          })
          .catch((res) => {
            console.log("then", {
              res,
              message: res.message,
            });
            parentElement.classList.add("error");
            parentElement.classList.remove("valid");
            inputWrapper.classList.add("error");
          });
      }
    });
  });
};
let inputFields = document.querySelectorAll(".form-control");
updateInputFields(inputFields);
