import * as yup from 'yup'

const receiptForm = (form, callback, initialValues = {}, index) => {
  const expense = {
    date: '',
    amount: 0,
    file: null,
    message: '',
    ...initialValues
  }

  // set initial values if any
  if(Object.keys(initialValues).length > 0){
    const dateInput = form.querySelector('#form-input-date')
    const amountInput = form.querySelector('#form-input-amount')
    const messageInput = form.querySelector('#form-input-message')
    const fileInput = form.querySelector('#form-input-file')

    dateInput.closest('.form-group').classList.add('filled')
    amountInput.closest('.form-group').classList.add('filled')
    messageInput.closest('.form-group').classList.add('filled')
    fileInput.closest('.form-group').classList.add('hidden')

    const fileField = document.createElement('div')
    const deleteButton = document.createElement('button')
    deleteButton.classList.add('ml-4')
    deleteButton.innerHTML = translatedLabels.delete
    deleteButton.addEventListener('click', (e) => {
      e.preventDefault()
      expense.file = null
      fileInput.closest('.form-group').classList.remove('hidden')
      fileField.remove()
    })
    fileField.classList.add('form-group','col-sm-12','col-xs-12','form-group-file')
    fileField.innerHTML = `${expense.file.name}`
    fileField.appendChild(deleteButton)
    fileInput.closest('.form-group').after(fileField)

    dateInput.value = expense.date
    amountInput.value = expense.amount
    messageInput.value = expense.message
  }

  form.querySelector('#form-input-date').addEventListener('change', (e) => {
    expense.date = e.target.value
    form.querySelector('#form-input-date').parentElement.classList.remove('error')
  })
  form.querySelector('#form-input-date').addEventListener('keyup', (e) => {
    expense.date = e.target.value
    form.querySelector('#form-input-date').parentElement.classList.remove('error')
  })

  form.querySelector('#form-input-amount').addEventListener('change', (e) => {
    expense.amount = isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value)
    form.querySelector('#form-input-amount').parentElement.classList.remove('error')
  })
  form.querySelector('#form-input-amount').addEventListener('keyup', (e) => {
    expense.amount = isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value)
    form.querySelector('#form-input-amount').parentElement.classList.remove('error')
  })

  form.querySelector('#form-input-message').addEventListener('change', (e) => {
    expense.message = e.target.value
    form.querySelector('#form-input-message').parentElement.classList.remove('error')
  })
  form.querySelector('#form-input-message').addEventListener('keyup', (e) => {
    expense.message = e.target.value
    form.querySelector('#form-input-message').parentElement.classList.remove('error')
  })

  form.querySelector('#form-input-file').addEventListener('change', (e) => {
    expense.file = e.target.files[0]
    form.querySelector('#form-input-file').parentElement.classList.remove('error')
  })

  form.addEventListener('submit', async (e) => {
    e.preventDefault()
    await submit(form, callback, expense, index)
  })
}

const submit = async (form, callback, expense, index) => {
  const schema = yup.object().shape({
    date: yup.string().required('Dato er påkrævet'),
    amount: yup.number().required('Beløb er påkrævet'),
    file: yup.mixed().required('Fil er påkrævet'),
    message: yup.string()
  })

  try {
    await schema.validate(expense, { abortEarly: false })
    const fileSize = expense.file.size
    
    if(fileSize > 12582912){
      form.querySelector(`#form-input-file`).parentElement.classList.add('error')
      return false
    }

    callback(expense, index)
  } catch (err) {
    err.inner.forEach(e => {
      form.querySelector(`#form-input-${e.path}`).parentElement.classList.add('error')
    })
  }
}

export default receiptForm